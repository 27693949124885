import { useQuery } from 'react-query';
import { captureException } from '@sentry/react';
import useFetchAuth from 'hooks/useFetchAuth';
import useProducts from 'hooks/useProducts';

const useDuckdbWarmer = () => {
    const { isUsingDemoDataForPropensities: isUsingDemoData } = useProducts();

    const fetchAuth = useFetchAuth();
    const url = `/duckdb_warmer/run?is_demo_data=${isUsingDemoData}`;
    const tags = ['duckdb_warmer', isUsingDemoData];

    return useQuery(tags, () => fetchAuth(url), {
        retry: false,
        refetchOnMount: false,
        staleTime: 10 * 60 * 1000, // keep request cached for 10 minutes
        onError: (err) => {
            captureException(err, { tags });
            console.log(err);
        }
    });
};

export default useDuckdbWarmer;
