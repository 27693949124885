import moment from 'moment';
import { read } from 'xlsx';
import demoDatavalidationSummaryReviewDefault from 'hooks/demoData/validationSummaryReview.json';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function convertSlugToUrl(slug, parameters = {}, query_params = {}) {
    let url = slug;
    Object.entries(parameters).forEach(([key, value]) => {
        url = url.replace(`:${key}`, value);
    });
    url = url.split('?').join('');
    if (Object.keys(query_params).length > 0) {
        url = url + '?';
        Object.entries(query_params).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach((v) => {
                    url = url + key + '=' + encodeURIComponent(v) + '&';
                });
            } else {
                url = url + key + '=' + encodeURIComponent(value) + '&';
            }
        });
        url = url.slice(0, -1);
    }
    if (url && url.split('//').length > 0) {
        url = url.split('//').join('/');
    }
    return url;
}

export function parseHtmlEnteties(str) {
    return str.replace(/&#(\d+);/g, function (_match, dec) {
        return String.fromCharCode(dec);
    });
}

export function columnsAndDataToObjects(columns = [], data = []) {
    const result = [];
    data.forEach((row) => {
        const item = {};
        row.forEach((cell, index) => {
            item[columns[index]] = cell;
        });
        result.push(item);
    });
    return result;
}

export function getNumberFormat(number) {
    return new Intl.NumberFormat().format(number || 0);
}

export function compactNumber(num = '', decimals) {
    const fixedDecimals = decimals || 1;
    const regex = new RegExp(`\\.0{1,${fixedDecimals}}$`);
    if (num >= 1e6) {
        return (num / 1e6).toFixed(fixedDecimals).replace(regex, '') + 'M';
    }
    if (num >= 1e3) {
        return (num / 1e3).toFixed(fixedDecimals).replace(regex, '') + 'k';
    }
    return num.toFixed(fixedDecimals).replace(regex, '').toLocaleString();
}

export function getNumberFormatWithCurrency(number, currencyCode) {
    return new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        currency: currencyCode,
        maximumFractionDigits: 2
    }).format(number || 0);
}

export function convertToPercentage(number, options = {}) {
    let value = Math.round(number * 10000) / 100;
    if (options.round) {
        value = Math.round(value);
    }
    return new Intl.NumberFormat().format(value);
}

export function getLocalStorage() {
    if (!localStorage['demo_data']) {
        localStorage['demo_data'] = JSON.stringify(demoDatavalidationSummaryReviewDefault);
    }
    let demoDataValidationSummaryReview;
    try {
        demoDataValidationSummaryReview = JSON.parse(localStorage['demo_data']);
    } catch (ex) {
        console.log('Error during parse localStorage, restore default values. ', ex.message);
        localStorage['demo_data'] = JSON.stringify(demoDatavalidationSummaryReviewDefault);
        demoDataValidationSummaryReview = demoDatavalidationSummaryReviewDefault;
    }

    return demoDataValidationSummaryReview;
}

export async function timeout(ms) {
    return new Promise((res) => setTimeout(res, ms));
}

export function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

export function formatBytes(bytes, decimals) {
    if (bytes === 0) return '0 Bytes';
    var k = 1024,
        dm = decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i].toLocaleLowerCase();
}

export function containsOnlyNumbersAndDot(str) {
    return /^[0-9.]+$/.test(str);
}

export function negativeNumberAndDot(str) {
    return /^[-0-9][0-9.]*$/.test(str);
}

export function normalizeTitle(text, crm_type = '') {
    const parts = text.split('_');
    if ((crm_type === 'sf_npsp' && parts[parts.length - 1] === 'c') || crm_type === 'dynamics') {
        return text;
    }

    return parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
}

export function getSupForNumber(value) {
    const valueEnding = !value ? 0 : parseInt(value) % 10;
    const isSpecialCase = value && value > 10 && value < 20 ? true : false;

    if (!isSpecialCase && valueEnding === 1) {
        return 'st';
    }
    if (!isSpecialCase && valueEnding === 2) {
        return 'nd';
    }
    if (!isSpecialCase && valueEnding === 3) {
        return 'rd';
    }
    return 'th';
}

export function convertDateToDaysAgo(lastModifiedDate, isUTC = false) {
    moment.relativeTimeThreshold('m', 59);
    moment.relativeTimeThreshold('s', 1);
    moment.relativeTimeThreshold('ss', 1);
    moment.relativeTimeThreshold('h', 24);
    moment.relativeTimeThreshold('d', 7);
    moment.relativeTimeThreshold('w', 4);
    moment.relativeTimeThreshold('M', 12);

    const hourAgoThreshold = ['in a few seconds', 'a minute ago', 'an hour ago'];
    const momentDate = isUTC ? moment.utc(lastModifiedDate) : moment(lastModifiedDate);

    if (hourAgoThreshold.includes(momentDate.fromNow())) {
        const duration = moment.duration(moment().diff(momentDate));
        const durationInMinutes = duration.asMinutes();
        if (durationInMinutes < 1) {
            return 'a few seconds ago';
        }
        if (durationInMinutes < 1.5) {
            return '1 minute ago';
        }
        if (momentDate.fromNow() === 'an hour ago') {
            return '1 hour ago';
        }
        return `${Math.ceil(durationInMinutes)} minutes ago`;
    }

    if (momentDate.fromNow() === 'a day ago') {
        return '1 day ago';
    }

    return momentDate.fromNow();
}

export function convertDateToNumDaysAgo(lastModifiedDate) {
    if (['null', '', null, 0, '0'].includes(lastModifiedDate)) {
        return 'Unknown';
    }

    const now = moment();
    const lastModified = moment(lastModifiedDate);
    const duration = moment.duration(now.diff(lastModified));
    const hours = duration.asHours();

    return convertHoursToDetailedTimeAgo(hours);
}

export function convertHoursToDetailedTimeAgo(hours) {
    if (hours === null || hours === undefined || isNaN(hours) || hours < 0) {
        return 'Unknown';
    }

    const now = moment();
    const pastDate = now.clone().subtract(hours, 'hours');
    const duration = moment.duration(now.diff(pastDate));

    const years = duration.years();
    const months = duration.months();
    const remainingHours = duration.hours();
    const remainingDays = duration.days();

    let timeAgo = '';

    if (years > 0) {
        timeAgo += years === 1 ? '1 year' : `${years} years`;
    }
    // X months, and Y years

    if (months > 0) {
        const monthsString = months === 1 ? '1 month' : `${months} months`;
        if (timeAgo) {
            timeAgo = `${timeAgo}, and ${monthsString}`;
        } else {
            timeAgo += months === 1 ? '1 month' : `${months} months`;
        }
    }

    if (!timeAgo && remainingDays > 0) {
        timeAgo = remainingDays === 1 ? '1 day' : `${remainingDays} days`;
    }

    if (!timeAgo && remainingHours > 0) {
        timeAgo = remainingHours === 1 ? 'an hour' : `${remainingHours} hours`;
    }

    if (!timeAgo) {
        timeAgo = 'less than an hour';
    } else {
        timeAgo += ' ago';
    }

    return timeAgo;
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function validateEmail(email_value) {
    const result =
        /^llorentegerman[+]?[a-zA-Z0-9._%+-]*@(gmail).com$/.test(email_value.toLocaleLowerCase()) ||
        /^([\w.-]+)[+]?[a-zA-Z0-9._%+-]*@(?!gmail\b)(?!hotmail\b)(?!yahoo\b)(?!aol\b)(?!outlook\b).([\w.-]+)$/.test(
            email_value.toLocaleLowerCase()
        ) ||
        'Please provide a work email (gmail, hotmail, yahoo, outlook, aol emails are not valid)';
    return result;
}

export const yearsBack = (back, includeCurrent = false) => {
    const year = new Date().getFullYear();
    let result = [];
    if (includeCurrent) {
        result = Array.from({ length: back }, (v, i) => year - back + i + 1).reverse();
    } else {
        result = Array.from({ length: back }, (v, i) => year - back + i).reverse();
    }
    return result;
};

export const nextYear = () => {
    const year = new Date().getFullYear();
    return year + 1;
};

export function calculateYearsBack(numberOfYears, fromCurrentYear = false) {
    return yearsBack(numberOfYears, fromCurrentYear).map((year) => {
        return {
            title: year,
            value: year
        };
    });
}

export function equalArrays(array1, array2) {
    return (
        array1.length === array2.length &&
        array1.every(function (value, index) {
            return value === array2[index];
        })
    );
}

export async function getCSVFileContent(file, peekFile = false) {
    async function parseXlsx(file, peekFile) {
        const workBook = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = read(data, {
                    type: 'array',
                    dense: true,
                    cellDates: true,
                    sheetRows: peekFile ? 11 : 0 //0 means read everything
                });
                resolve(workbook);
            };
            reader.readAsArrayBuffer(file);
        });

        const result = parseXlsxWorkBookToResultsArray(workBook);
        return result;
    }

    async function parseCSV(file) {
        const text = await new Response(file).text();
        // solution from: https://stackoverflow.com/a/41563966
        let p = '',
            row = [''],
            ret = [row],
            i = 0,
            r = 0,
            s = !0,
            l;
        for (l of text) {
            if ('"' === l) {
                if (s && l === p) row[i] += l;
                s = !s;
            } else if (',' === l && s) l = row[++i] = '';
            else if ('\n' === l && s) {
                if ('\r' === p) row[i] = row[i].slice(0, -1);
                row = ret[++r] = [(l = '')];
                i = 0;
            } else row[i] += l;
            p = l;
        }

        const headers = ret[0];
        let result = [];
        for (let count = 1; count < ret.length; count++) {
            const item = {};
            for (let index = 0; index < headers.length; index++) {
                const headerKey = headers[index];
                item[headerKey] = (ret[count][index] || '').trim();
                if (['', 'null'].includes(item[headerKey])) {
                    item[headerKey] = null;
                }
            }
            result.push(item);
        }
        return result;
    }

    try {
        let content = [];
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            content = await parseXlsx(file, peekFile);
        } else {
            content = await parseCSV(file);
        }

        return content;
    } catch (ex) {
        console.error('Error parsing csv/xlsx file content', ex);
        return false;
    }
}

function parseXlsxWorkBookToResultsArray(workBook) {
    if (!workBook || !workBook.SheetNames || !workBook.SheetNames[0] || !workBook.Sheets) {
        return [];
    }

    const ws = workBook.Sheets[workBook.SheetNames[0]];
    if (!ws || !Array.isArray(ws)) {
        return [];
    }

    const result = [];
    let header = [];
    ws.forEach((row, i) => {
        if (i === 0) {
            header = row.map((cell) => cell.w);
        } else if (i !== 0) {
            const item = {};
            for (let j = 0; j < row.length; j++) {
                const headerName = header[j];
                const cell = row[j];
                if (!cell) {
                    item[headerName] = null;
                } else {
                    item[headerName] = cell.v.toString().trim();
                    if (['', 'null'].includes(item[headerName])) {
                        item[headerName] = null;
                    }
                }
            }
            result.push(item);
        }
    });
    return result;
}

export function compareDates(date1Ts, date2Ts, order = 'last') {
    const date1 = date1Ts ? new Date(date1Ts) : null;
    const date2 = date2Ts ? new Date(date2Ts) : null;

    if (date1 && date2) {
        if (order === 'first') {
            return date1 < date2 ? date1Ts : date2Ts;
        } else {
            return date1 > date2 ? date1Ts : date2Ts;
        }
    } else if (date1) {
        return date1Ts;
    } else if (date2) {
        return date2Ts;
    } else {
        return null;
    }
}

export function donorDataInferred(fallback, value, inferredValue, hasDataInferenceEnabled) {
    if (hasDataInferenceEnabled === false) {
        return [value || '-', false];
    }
    if (value === true || value === false || value === 'true' || value === 'false') {
        if (value === true || value === 'true') {
            return ['Yes', false];
        }
        if (value === false || value === 'false') {
            return ['No', false];
        }
    }
    if (value && (value !== fallback || (value === fallback && value === inferredValue))) {
        return [value, false];
    }
    if (inferredValue) {
        return [inferredValue, true];
    }
    return ['-', false];
}

export function onlyUniqueValuesInArray(value, index, self) {
    return self.indexOf(value) === index;
}

export function formatDateTime(datetime) {
    var momentDate = moment(datetime);
    var formattedDate = momentDate.format('D MMM YYYY HH:mm [hs]');
    return formattedDate;
}

export function getDateByMonthBack(monthsAgo) {
    const currentDate = new Date();
    return currentDate.setMonth(currentDate.getMonth() - monthsAgo, 1);
}

export function getCurrentTimestamp() {
    return new Date().getTime();
}

export function cn(...inputs) {
    return twMerge(clsx(inputs));
}

/**
 * The purpose of this function is to align the labels and data arrays of the
 * current and prospect segments for the mid and major donor reports.
 * This function ensures that both segments have the same set of labels,
 * and it fills in zeros for any missing data points to maintain alignment.
 * This then ensures that the charts have the same colour for each label when displayed.
 */
export function unifySegmentLabelsAndDataForCharts(currentSegments, prospectSegments) {
    // Create a Map for prospectSegments for quick lookup by title
    const prospectSegmentsMap = new Map();
    prospectSegments.forEach((segment) => {
        if (segment && segment.title) {
            prospectSegmentsMap.set(segment.title, segment);
        }
    });

    currentSegments.forEach((currentSegment) => {
        const prospectSegment = prospectSegmentsMap.get(currentSegment.title);
        if (prospectSegment) {
            // Create a Set for all unique labels from both segments
            const allLabelsSet = new Set([...currentSegment.labels, ...prospectSegment.labels]);
            const allLabels = Array.from(allLabelsSet);

            // Create a map of labels to their new indices to maintain the correct order.
            // This is important because the charting tool links data points to labels based on their array indices.
            const labelIndexMap = new Map();
            allLabels.forEach((label, index) => {
                labelIndexMap.set(label, index);
            });

            // Initialize new data arrays with zeros to accommodate labels that are missing in one segment.
            // This ensures that both data arrays align with the allLabels array, preventing any mismatches.
            const currentData = new Array(allLabels.length).fill(0);
            const prospectData = new Array(allLabels.length).fill(0);

            // Populate the new currentData array by inserting values from the existing currentSegment.data array.
            // We do this by finding the index of each label in the new allLabels array and placing the corresponding data value there.
            currentSegment.labels.forEach((label, i) => {
                const index = labelIndexMap.get(label);
                currentData[index] = currentSegment.data[i];
            });

            // Populate the new prospectData array the same way as we did for the currentData array.
            prospectSegment.labels.forEach((label, i) => {
                const index = labelIndexMap.get(label);
                prospectData[index] = prospectSegment.data[i];
            });

            // Update the original segments with the new allLabels array and the populated data arrays
            currentSegment.labels = allLabels;
            currentSegment.data = currentData;

            prospectSegment.labels = allLabels;
            prospectSegment.data = prospectData;
        }
    });
    return { currentSegments, prospectSegments };
}
