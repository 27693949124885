import React from 'react';
import { enableJustOneMenuItem, enableAllMenuItems } from '../utilities';

const functionsAttached = {};

export function getModelPerformanceSteps({ classes, tourType }) {
    const steps = [
        {
            content: (
                <div>
                    <div className={classes.smallTitle}>Model Performance is located here</div>
                    <div>
                        Click on the Model Performance tab to learn more about your Dataro
                        predictive models. Once you’ve run a few campaigns this will be a handy
                        place to visit.
                    </div>
                </div>
            ),
            placement: 'top',
            target: '#menu_predict',
            nextText: 'Next',
            backText: 'Back',
            disableScrolling: true,
            disableOverlay: true,
            hasSpotlightClicks: true,
            stepStyles: {
                containerWidth: 450,
                containerMaxWidth: 450
            },
            id: 'model_performance_1'
        },
        {
            content: (
                <div>
                    <div className={classes.smallTitle}>Uncovering the magic of AI</div>
                    <div>
                        This predictive model scores 95 out of 100 and is ready to help you get more
                        gifts! Scroll down to see how your model was created including the Top 10
                        data points.
                        <br />
                        <br />
                    </div>
                </div>
            ),
            placement: 'top',
            target: '#model-performance-items-container > div:first-child > div',
            nextText: 'Next',
            backText: 'Back',
            disableScrolling: true,
            disableOverlay: true,
            stepStyles: {
                containerWidth: 450,
                containerMaxWidth: 450
            },
            id: 'model_performance_3'
        },
        {
            content: (
                <div>
                    <div className={classes.smallTitle}>
                        Verify the accuracy of your own predictions
                    </div>
                    <div>
                        Use this graph to see how accurate the Dataro appeal giving model is at
                        predicting donor behaviour for your campaign. This graph shows that the top
                        1000 donors predicted to give in the appeal gave at a really high response
                        rate!
                        <br />
                        <br />
                    </div>
                </div>
            ),
            placement: 'right-start',
            target: '#dataro-chart-main-container',
            disableOverlay: true,
            nextText: 'Next',
            backText: 'Back',
            hasSpotlightClicks: true,
            id: 'model_performance_4'
        },
        {
            content: (
                <div>
                    <div className={classes.smallTitle}>You’re all set!</div>
                    <div>
                        You are all set to start running smarter fundraising campaigns that get more
                        gifts.
                        <br />
                        To manage your subscriptions or add more users and propensities go to the
                        Settings tab.
                        <br />
                        <br />
                        If you have any questions or issues, jump onto the chat on the lower right
                        corner of your screen and we would be happy to help.
                        <br />
                        <br />
                    </div>
                </div>
            ),
            placement: 'top',
            target: '#menu_settings',
            nextText: 'Start Fundraising Intelligence Tour',
            disableScrolling: true,
            stepStyles: {
                buttonWidth: 260,
                containerWidth: 500,
                containerMaxWidth: 500,
                buttonNextStyles: {
                    height: 32,
                    padding: '3px 3px'
                }
            },
            id: 'model_performance_5'
        }
    ];
    if (tourType === 'fundraising_intelligence') {
        steps[steps.length - 1].nextText = 'Finish tour';
    }
    return steps;
}

export function onModelPerformanceStepEffect({ steps = [], stepIndex, localState, theme }) {
    const currentStep = steps[stepIndex] || {};

    const btnNextSelector = '#btn_onboarding_next';
    const subMenuModelPerformanceSelector = '#submenu_performance';

    function onNextClick() {
        const nextBtnElement = document.querySelector(btnNextSelector);
        nextBtnElement && nextBtnElement.click();
    }

    return [
        () => {
            if (currentStep.id === 'model_performance_1' && localState['model_performance_1']) {
                window.scrollTo(0, 0);
                enableJustOneMenuItem({
                    mainSelector: '#menu_predict',
                    selector: subMenuModelPerformanceSelector,
                    onNextClick,
                    functionsAttached
                });
            }
            if (currentStep.id === 'model_performance_3' && localState['model_performance_3']) {
                document.querySelector('#main_container').style.marginTop = '120px';
                const firstModelDivSelector = '#model-performance-div-y_meta_dm_appeal > div';
                let fistModelDiv = document.querySelector(firstModelDivSelector);
                if (fistModelDiv) {
                    fistModelDiv.click();
                } else {
                    setTimeout(() => {
                        fistModelDiv = document.querySelector(firstModelDivSelector);
                        fistModelDiv.click();
                    }, 2000);
                }
            }
            if (currentStep.id === 'model_performance_5' && localState['model_performance_5']) {
                window.scrollTo(0, 0);
            }
        },
        () => {
            if (['model_performance_3'].includes(currentStep.id)) {
                enableAllMenuItems({
                    mainSelector: '#menu_predict',
                    selector: subMenuModelPerformanceSelector,
                    functionsAttached
                });
            }
            if (currentStep.id === 'model_performance_4' && localState['model_performance_4']) {
                document.querySelector('#main_container').style.marginTop = '60px';
            }
        }
    ];
}
