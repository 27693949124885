import React, { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import RadioComponent from './RadioComponent';

function FormRadioComponent({
    label,
    name,
    required,
    defaultChecked,
    showErrorMessageBelow,
    ...otherProps
}) {
    const { control, formState, setValue, getValues } = useFormContext();
    const isTouched = formState.touchedFields[name] || formState.isSubmitted;
    const fieldError = formState.errors[name];
    const errorMessage = isTouched && fieldError && fieldError.message;
    const currentValue = (getValues() || {})[name];
    const props = otherProps;
    // TODO: check if default values is fixed in next patch https://github.com/react-hook-form/react-hook-form/issues/2308

    useEffect(() => {
        if (defaultChecked && !isTouched) {
            setValue(name, otherProps.value);
        }
    }, [defaultChecked, isTouched, name, setValue, otherProps.value]);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultChecked ? otherProps.value : ''}
            rules={{ required }}
            render={({ field }) => (
                <RadioComponent
                    name={name}
                    id={name}
                    currentValue={currentValue}
                    label={label}
                    errorMessage={errorMessage}
                    showErrorMessageBelow={showErrorMessageBelow}
                    onChange={(e) => {
                        field.onChange(e.target.value);
                        setValue(name, e.target.value);
                    }}
                    checked={field.value === otherProps.value}
                    {...props}
                />
            )}
        />
    );
}

export default FormRadioComponent;
