import React, { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import SelectComponent from './SelectComponent';

function FormSelectComponent({
    label,
    name,
    required,
    min,
    max,
    minLength,
    maxLength,
    pattern,
    validate,
    defaultValue,
    compareWithOther,
    showErrorMessageBelow,
    onChange: onValueChange,
    ...otherProps
}) {
    const { control, formState, setValue, getValues } = useFormContext();
    const isTouched = formState.touchedFields[name] || formState.isSubmitted;
    const fieldError = formState.errors[name];
    const errorMessage = isTouched && fieldError && fieldError.message;
    const props = otherProps;
    // TODO: check if default values is fixed in next patch https://github.com/react-hook-form/react-hook-form/issues/2308

    if (props.type === 'number') {
        props.step = 'any';
    }
    useEffect(() => {
        if (defaultValue && !isTouched) {
            setValue(name, defaultValue);
        }
    }, [defaultValue, isTouched, name, setValue]);

    const validateProp = {
        ...validate
    };
    if (compareWithOther) {
        validateProp.compareWithOther = (value) => {
            const values = getValues();
            return compareWithOther(value, values);
        };
    }

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={{ required, min, max, minLength, maxLength, pattern, validate: validateProp }}
            render={({ field }) => (
                <SelectComponent
                    type='text'
                    onChange={(e) => {
                        setValue(name, e.target.value);
                        field.onChange(e);
                        onValueChange && onValueChange(e);
                    }}
                    name={name}
                    id={name}
                    defaultValue={defaultValue}
                    label={label}
                    errorMessage={errorMessage}
                    showErrorMessageBelow={showErrorMessageBelow}
                    {...props}
                />
            )}
        />
    );
}

export default FormSelectComponent;
