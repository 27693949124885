import React, { useEffect, Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import SLUGS from 'resources/slugs';
import { useListView } from 'hooks/useDonorListView';
import useDuckdbWarmer from 'hooks/useDuckdbWarmer';
import useProducts from 'hooks/useProducts';
import LoadingComponent from 'components/loading';
import '../App.css';

const SentryRoute = Sentry.withSentryRouting(Route);

const Home = lazy(() => import('./home/Home'));
const PredictRoutes = lazy(() => import('./predict/PredictRoutes'));
const DonorsSegmentsRoutes = lazy(() => import('./donorsAndSegments/DonorsAndSegmentsRoutes.js'));
const CreateCampaign = lazy(() => import('./createCampaign/CreateCampaign'));
const FundraisingIntelligenceSection = lazy(() => import('./fundraisingIntelligence/ReportRoutes'));
const HelpAndSupport = lazy(() => import('./helpAndsupport/HelpAndSupport'));
const Settings = lazy(() => import('./settings/Settings'));
const CampaignTagging = lazy(() => import('./dataHub/validation/campaignTagging'));
const CampaignBulkTagging = lazy(() => import('./dataHub/validation/CampaignBulkTagging'));
const DonorList = lazy(() => import('./donorLists/DonorList'));
const AIAssist = lazy(() => import('./aiAssist/AIAssist'));
const AIAssistSettings = lazy(() => import('./aiAssist/AIAssistSettings'));
const Connections = lazy(() => import('./connections/Connections'));
const DragAndDropRoutes = lazy(() => import('./connections/dragAndDrop/DragAndDropRoutes'));
const DataHub = lazy(() => import('./dataHub/dataHubRoutes'));
const DataMapping = lazy(() => import('./dataHub/dataMapping/DataMapping'));
const Page404Component = lazy(() => import('./errors/Page404Component'));
const DataInspector = lazy(() => import('./dataHub/dataInspector/dataInspector'));
const DonorView = lazy(() => import('./donorView/DonorView'));
const AIAnalyst = lazy(() => import('./fundraisingIntelligence/aiAnalyst/AIAnalyst'));
const FacebookTerms = lazy(() => import('./connections/facebook/components/FacebookTerms'));
const GoogleAdsTerms = lazy(() => import('./connections/googleAds/components/GoogleAdsTerms'));
const MailchimpTerms = lazy(() => import('./connections/mailchimp/components/MailchimpTerms'));
const VirtuousTerms = lazy(() => import('./connections/virtuous/components/VirtuousTerms'));
const CiviCRMTerms = lazy(() => import('./connections/CiviCRM/components/CiviCRMTerms'));
const RaiselyTerms = lazy(() => import('./connections/raisely/components/RaiselyTerms'));
const HubSpotTerms = lazy(() => import('./connections/hubspot/components/HubSpotTerms'));
const SavedDonorLists = lazy(() => import('./savedDonorLists/SavedDonorLists'));
const CampaignAnalysis = lazy(() =>
    import('./fundraisingIntelligence/fundraisingInsights/CampaignAnalysisPage')
);
const DuplicateDetection = lazy(() =>
    import('./dataHub/duplicateDetection/DuplicateDetectionPage')
);
const Forecast = lazy(() => import('./forecast'));
const AffinityRoutes = lazy(() => import('./affinity'));

// Predict routes
const Campaign = lazy(() => import('./campaign/results/CampaignResults'));
const CampaignResultDetails = lazy(() => import('./campaign/details/CampaignResultDetails'));
const ModelPerformance = lazy(() => import('./modelperformance/ModelPerformance'));
const PredictionInsights = lazy(() => import('./insights/PredictionInsights'));
const CampaignSize = lazy(() => import('./campaignSize/CampaignSize'));
const CampaignSizeRecommendations = lazy(() =>
    import('./campaignSizeRecommendations/CampaignSizeRecommendations')
);
const CampaignInsights = lazy(() => import('./campaign/campaignInsights/CampaignInsights'));

function PrivateRoutes() {
    const { isLoading, hasCreateCampaignEnabled, hasAIAnalystEnabled, hasAffinityEnabled } =
        useProducts();

    useEffect(() => {
        document.body.style.backgroundColor = '#ffffff';
    }, []);

    // start pre-fetching section
    // eslint-disable-next-line no-unused-vars
    const prefetchResultListView = useListView({
        filterMatchRule: 'all',
        filters: '{}',
        pageId: '0',
        pageSize: '10',
        sortAsc: 'false',
        sortField: ''
    });
    useDuckdbWarmer();
    // end pre-fetching section

    if (isLoading) {
        return <LoadingComponent loading={isLoading} />;
    }

    return (
        <>
            <Suspense fallback={<LoadingComponent loading />}>
                <Switch>
                    <SentryRoute exact path={SLUGS.home} component={Home} />
                    <SentryRoute path={SLUGS.predict} render={() => <PredictRoutes />} />
                    <SentryRoute
                        path={SLUGS.donorsAndSegments}
                        render={() => <DonorsSegmentsRoutes />}
                    />
                    <SentryRoute
                        exact
                        path={SLUGS.campaignResultDetails}
                        component={CampaignResultDetails}
                    />
                    <SentryRoute exact path={SLUGS.campaignResults} component={Campaign} />
                    <SentryRoute exact path={SLUGS.facebookTerms} component={FacebookTerms} />
                    <SentryRoute exact path={SLUGS.googleAdsTerms} component={GoogleAdsTerms} />
                    <SentryRoute exact path={SLUGS.mailchimpTerms} component={MailchimpTerms} />
                    <SentryRoute exact path={SLUGS.virtuousTerms} component={VirtuousTerms} />
                    <SentryRoute exact path={SLUGS.civiCRMTerms} component={CiviCRMTerms} />
                    <SentryRoute exact path={SLUGS.raiselyTerms} component={RaiselyTerms} />
                    <SentryRoute exact path={SLUGS.hubspotTerms} component={HubSpotTerms} />
                    <SentryRoute exact path={SLUGS.predictions} component={PredictionInsights} />
                    <SentryRoute exact path={SLUGS.performance} component={ModelPerformance} />
                    <SentryRoute path={SLUGS.campaignSize} component={CampaignSize} />
                    <SentryRoute
                        path={SLUGS.campaignSizeRecommendations}
                        component={CampaignSizeRecommendations}
                    />
                    <SentryRoute path={SLUGS.campaignInsights} component={CampaignInsights} />
                    {hasCreateCampaignEnabled && (
                        <SentryRoute exact path={SLUGS.createCampaign} component={CreateCampaign} />
                    )}
                    <SentryRoute
                        path={SLUGS.fundraisingIntelligence}
                        component={FundraisingIntelligenceSection}
                    />
                    <SentryRoute path={SLUGS.donorList} component={DonorList} />
                    <SentryRoute path={SLUGS.aiAssistSettings} component={AIAssistSettings} />
                    <SentryRoute path={SLUGS.aiAssist} component={AIAssist} />
                    <SentryRoute path={SLUGS.donorView} component={DonorView} />
                    <SentryRoute path={SLUGS.connections} component={Connections} />
                    <SentryRoute path={SLUGS.dragAndDrop} component={DragAndDropRoutes} />
                    <SentryRoute path={SLUGS.dataHub} component={DataHub} />
                    <SentryRoute path={SLUGS.dataRecordMapping} component={DataMapping} />
                    <SentryRoute exact path={SLUGS.support} component={HelpAndSupport} />
                    <SentryRoute exact path={SLUGS.campaignTagging} component={CampaignTagging} />
                    <SentryRoute
                        exact
                        path={SLUGS.campaignBulkTagging}
                        component={CampaignBulkTagging}
                    />
                    <SentryRoute exact path={SLUGS.settings} component={Settings} />
                    <SentryRoute exact path={SLUGS.dataInspector} component={DataInspector} />
                    <SentryRoute exact path={SLUGS.error404} component={Page404Component} />
                    {hasAIAnalystEnabled && (
                        <SentryRoute exact path={SLUGS.aiAnalyst} component={AIAnalyst} />
                    )}
                    <SentryRoute
                        exact
                        path={SLUGS.fundraisingInsights}
                        component={CampaignAnalysis}
                    />
                    <SentryRoute path={SLUGS.savedDonorLists} component={SavedDonorLists} />
                    <SentryRoute path={SLUGS.duplicateDetection} component={DuplicateDetection} />
                    <SentryRoute path={SLUGS.forecast} component={Forecast} />
                    {hasAffinityEnabled && (
                        <SentryRoute path={SLUGS.affinity} component={AffinityRoutes} />
                    )}
                    <Redirect to={SLUGS.home} />
                </Switch>
            </Suspense>
            <LoadingComponent loading={isLoading} />
        </>
    );
}

export default PrivateRoutes;
